import {session} from '@manuelpuyol/turbo'
import {isFeatureEnabled} from '../../features'

interface ProgressBar {
  setValue(n: number): void
  hide(): void
  show(): void
}

interface BrowserAdapter {
  progressBar: ProgressBar
}

export interface CacheNode {
  title: string | null | undefined
  transients: Element[]
  bodyClasses: string | null | undefined
}

const adapter = session.adapter as typeof session.adapter & BrowserAdapter

const DATA_TURBO_LOADED = 'data-turbo-loaded'

export function markTurboHasLoaded() {
  document.documentElement.setAttribute(DATA_TURBO_LOADED, '')
}

export function hasTurboLoaded() {
  return document.documentElement.hasAttribute(DATA_TURBO_LOADED)
}

// Check if Turbo is enabled.
export const isTurboEnabled = (): boolean => !isFeatureEnabled('PJAX_ENABLED')
// Check if an event target is a <turbo-frame>
export const isTurboFrame = (el: EventTarget | null): boolean => (el as Element)?.tagName === 'TURBO-FRAME'

// Start the ProgressBar at the top of the page.
export const beginProgressBar = () => {
  adapter.progressBar.setValue(0)
  adapter.progressBar.show()
}

// Complete the ProgressBar at the top of the page.
export const completeProgressBar = () => {
  adapter.progressBar.setValue(1)
  adapter.progressBar.hide()
}

// Check if the navigation is only a hash change.
export const isHashNavigation = (currentUrl: string, targetUrl: string): boolean => {
  const current = new URL(currentUrl, window.location.origin)
  const target = new URL(targetUrl, window.location.origin)

  return (
    Boolean(target.hash) &&
    current.host === target.host &&
    current.pathname === target.pathname &&
    current.search === target.search
  )
}

// Checks if two urls start with the same "/owner/repo" prefix.
export function isSameRepo(url1: string, url2: string): boolean {
  const path1 = url1.split('/', 3).join('/')
  const path2 = url2.split('/', 3).join('/')
  return path1 === path2
}

// Wait for all stylesheets to be loaded.
export async function waitForStylesheets() {
  const headStylesheets = document.head.querySelectorAll<HTMLLinkElement>('link[rel=stylesheet]')
  const loadedStylesheets = new Set([...document.styleSheets].map(stylesheet => stylesheet.href))
  const promises = []

  for (const stylesheet of headStylesheets) {
    if (stylesheet.href === '' || loadedStylesheets.has(stylesheet.href)) continue
    promises.push(waitForLoad(stylesheet))
  }

  await Promise.all(promises)
}

const waitForLoad = (stylesheet: HTMLLinkElement, timeout = 2000): Promise<void> => {
  return new Promise(resolve => {
    const onComplete = () => {
      stylesheet.removeEventListener('error', onComplete)
      stylesheet.removeEventListener('load', onComplete)
      resolve()
    }

    stylesheet.addEventListener('load', onComplete, {once: true})
    stylesheet.addEventListener('error', onComplete, {once: true})
    setTimeout(onComplete, timeout)
  })
}

// Replaces all elements with `data-turbo-replace` with the ones coming from the Turbo response.
export const replaceElements = (html: Document) => {
  const newElements = html.querySelectorAll('[data-turbo-replace]')
  const oldElements = [...document.querySelectorAll('[data-turbo-replace]')]

  for (const newElement of newElements) {
    const oldElement = oldElements.find(el => el.id === newElement.id)

    if (oldElement) {
      oldElement.replaceWith(newElement)
    }
  }
}

// Adds all missing stylesheets that come from the Turbo response.
export const addNewStylesheets = (html: Document) => {
  // Only add stylesheets that aren't already in the page
  for (const el of html.querySelectorAll<HTMLLinkElement>('link[rel=stylesheet]')) {
    if (
      !document.head.querySelector(
        `link[href="${el.getAttribute('href')}"],
           link[data-href="${el.getAttribute('data-href')}"]`
      )
    ) {
      document.head.append(el)
    }
  }
}

// Adds all missing scripts that come from the Turbo response.
export const addNewScripts = (html: Document) => {
  // Only add scripts that aren't already in the page
  for (const el of html.querySelectorAll<HTMLScriptElement>('script')) {
    if (!document.head.querySelector(`script[src="${el.getAttribute('src')}"]`)) {
      executeScriptTag(el)
    }
  }
}

// Load and execute scripts using standard script request.
const executeScriptTag = (script: HTMLScriptElement) => {
  const {src} = script

  // eslint-disable-next-line github/no-dynamic-script-tag
  const newScript = document.createElement('script')
  const type = script.getAttribute('type')
  if (type) newScript.type = type

  newScript.src = src
  if (document.head) {
    document.head.appendChild(newScript)
  }
}

// Compares all `data-turbo-track="reload"` reload with the ones coming from the Turbo response.
export const getChangedTrackedKeys = (html: Document): string[] => {
  const changedKeys = []
  for (const meta of html.querySelectorAll<HTMLMetaElement>('meta[data-turbo-track="reload"]')) {
    if (
      document.querySelector<HTMLMetaElement>(`meta[http-equiv="${meta.getAttribute('http-equiv')}"]`)?.content !==
      meta.content
    ) {
      changedKeys.push(formatKeyToError(meta.getAttribute('http-equiv')!))
    }
  }

  return changedKeys
}

export const getTurboCacheNodes = (html: Document): CacheNode => {
  const head = html.querySelector('[data-turbo-head]') || html.head

  return {
    title: head.querySelector('title')?.textContent,
    transients: [...head.querySelectorAll('[data-pjax-transient]')],
    bodyClasses: html.querySelector<HTMLMetaElement>('meta[name=turbo-body-classes]')?.content
  }
}

export const getDocumentAttributes = () => [...document.documentElement.attributes]

export const formatKeyToError = (key: string) => key.replace(/^x-/, '').replaceAll('-', '_')

export const dispatchTurboReload = (reason: string) =>
  document.dispatchEvent(new CustomEvent('turbo:reload', {detail: {reason}}))
