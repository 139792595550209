import {visit} from '@manuelpuyol/turbo'
import {isTurboEnabled} from '../behaviors/turbo/utils'
import pjax, {PjaxOptions} from '../behaviors/pjax'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function softNavigate(url: string, pjaxOptions: Omit<PjaxOptions, 'url'>, turboOptions?: any) {
  if (isTurboEnabled()) {
    visit(url, {...turboOptions})
  } else {
    pjax({...pjaxOptions, url})
  }
}
